import {
  useLocalParticipant,
  useMediaDevices,
  usePersistentUserChoices,
  useRoomContext,
} from "@livekit/components-react"
import {
  Box,
  BoxProps,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  ChatCircleText,
  Gear,
  IconProps,
  Microphone,
  MicrophoneSlash,
  PhoneSlash,
  Subtitles,
  SubtitlesSlash,
  VideoCamera,
  X,
} from "@phosphor-icons/react"
import { VideoCameraSlash } from "@phosphor-icons/react/dist/ssr"
import { Track } from "livekit-client"
import React, { useState } from "react"
import { useAppContext, useAppDispatchContext } from "../../app/Context"
import { StateAction } from "../../types/State"
import { replaceStreamTrack } from "../../utils/media"

const containerProps: BoxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  sx: {
    cursor: "pointer",
    backgroundColor: "rgba(36, 36, 36, 0.6)",
    "&:hover": {
      backgroundColor: "rgba(36, 36, 36, 0.8)",
    },
  },
  width: 60,
  height: 60,
  borderRadius: "50%",
}

const iconProps: IconProps = {
  size: 32,
  weight: "bold",
}

interface CustomControlsBarProps {
  showChat: boolean
  toggleChat: () => void
  disconnect: () => void
  lastMessage?: string
  audioState: boolean
  videoState: boolean
}

interface DeviceOption {
  label: string
  deviceId: string
}

const DeviceSelectionDialog = ({
  open,
  onClose,
  audioInputs,
  videoInputs,
  selectedAudioInput,
  selectedVideoInput,
  handleAudioInputChange,
  handleVideoInputChange,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(8px)",
        borderRadius: "24px",
        minWidth: { xs: "90vw", sm: "400px" },
        maxWidth: "500px",
        border: "1px solid rgba(255, 255, 255, 0.1)",
      },
    }}
  >
    <DialogTitle
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "white",
        pb: 1,
      }}
    >
      Device Settings
      <IconButton
        onClick={onClose}
        sx={{
          color: "white",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        <X size={24} />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="subtitle1"
          sx={{ color: "white", mb: 1, fontWeight: 500 }}
        >
          Microphone
        </Typography>
        <FormControl fullWidth>
          <Select
            value={selectedAudioInput}
            onChange={(e) => handleAudioInputChange(e.target.value)}
            sx={{
              backgroundColor: "rgba(36, 36, 36, 0.6)",
              backdropFilter: "blur(8px)",
              color: "white",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.1)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.2)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#DCF76D",
              },
              "& .MuiSvgIcon-root": {
                color: "white",
              },
              borderRadius: "12px",
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: "rgba(36, 36, 36, 0.95)",
                  backdropFilter: "blur(8px)",
                  borderRadius: "12px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  marginTop: "8px",
                  "& .MuiMenuItem-root": {
                    color: "white",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "rgba(220, 247, 109, 0.15)",
                      "&:hover": {
                        backgroundColor: "rgba(220, 247, 109, 0.25)",
                      },
                    },
                  },
                },
              },
            }}
          >
            {audioInputs.map((device) => (
              <MenuItem
                key={device.deviceId}
                value={device.deviceId}
                sx={{
                  "&.Mui-selected": {
                    color: "#DCF76D",
                  },
                }}
              >
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <Typography
          variant="subtitle1"
          sx={{ color: "white", mb: 1, fontWeight: 500 }}
        >
          Camera
        </Typography>
        <FormControl fullWidth>
          <Select
            value={selectedVideoInput}
            onChange={(e) => handleVideoInputChange(e.target.value)}
            sx={{
              backgroundColor: "rgba(36, 36, 36, 0.6)",
              backdropFilter: "blur(8px)",
              color: "white",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.1)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.2)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#DCF76D",
              },
              "& .MuiSvgIcon-root": {
                color: "white",
              },
              borderRadius: "12px",
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: "rgba(36, 36, 36, 0.95)",
                  backdropFilter: "blur(8px)",
                  borderRadius: "12px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  marginTop: "8px",
                  "& .MuiMenuItem-root": {
                    color: "white",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "rgba(220, 247, 109, 0.15)",
                      "&:hover": {
                        backgroundColor: "rgba(220, 247, 109, 0.25)",
                      },
                    },
                  },
                },
              },
            }}
          >
            {videoInputs.map((device) => (
              <MenuItem
                key={device.deviceId}
                value={device.deviceId}
                sx={{
                  "&.Mui-selected": {
                    color: "#DCF76D",
                  },
                }}
              >
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </DialogContent>
  </Dialog>
)

export function CustomControlsBar({
  showChat,
  toggleChat,
  disconnect,
  lastMessage,
  audioState,
  videoState,
}: CustomControlsBarProps) {
  const [isMicEnabled, setIsMicEnabled] = useState<boolean>(audioState)
  const [isCameraEnabled, setIsCameraEnabled] = useState<boolean>(videoState)
  const [showSubtitle, setShowSubtitle] = useState(false)
  const [subtitlesEnabled, setSubtitlesEnabled] = useState(true)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [audioInputs, setAudioInputs] = useState<DeviceOption[]>([])
  const [videoInputs, setVideoInputs] = useState<DeviceOption[]>([])

  const { userChoices, saveAudioInputDeviceId, saveVideoInputDeviceId } =
    usePersistentUserChoices({})

  const selectedAudioInput = userChoices?.audioDeviceId || ""
  const selectedVideoInput = userChoices?.videoDeviceId || ""

  const { localParticipant } = useLocalParticipant()
  const room = useRoomContext()
  const context = useAppContext()
  const dispatch = useAppDispatchContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const availableAudioInputs = useMediaDevices({ kind: "audioinput" })
  const availableVideoInputs = useMediaDevices({ kind: "videoinput" })

  React.useEffect(() => {
    if (availableAudioInputs.length > 0) {
      setAudioInputs(
        availableAudioInputs.map((device) => ({
          label: device.label,
          deviceId: device.deviceId,
        })),
      )
      const audioInput = selectedAudioInput || availableAudioInputs[0].deviceId
      dispatch({ type: StateAction.setAudioDevice, payload: audioInput })
      saveAudioInputDeviceId(audioInput)
    }

    if (availableVideoInputs.length > 0) {
      setVideoInputs(
        availableVideoInputs.map((device) => ({
          label: device.label,
          deviceId: device.deviceId,
        })),
      )
      const videoInput = selectedVideoInput || availableVideoInputs[0].deviceId
      dispatch({ type: StateAction.setVideoDevice, payload: videoInput })
      saveVideoInputDeviceId(videoInput)
    }
  }, [
    availableAudioInputs,
    availableVideoInputs,
    selectedAudioInput,
    selectedVideoInput,
    dispatch,
    saveAudioInputDeviceId,
    saveVideoInputDeviceId,
  ])

  React.useEffect(() => {
    if (lastMessage && subtitlesEnabled && !isMobile) {
      setShowSubtitle(true)
      const timer = setTimeout(() => {
        setShowSubtitle(false)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [lastMessage, subtitlesEnabled, isMobile])

  const toggleSubtitles = () => {
    setSubtitlesEnabled((prev) => !prev)
    if (subtitlesEnabled) {
      setShowSubtitle(false)
    }
  }

  const toggleMicrophone = async () => {
    if (localParticipant) {
      try {
        const enabled = !isMicEnabled
        await localParticipant.setMicrophoneEnabled(enabled)

        if (context.stream instanceof MediaStream) {
          const audioTracks = context.stream.getAudioTracks()
          audioTracks.forEach((track) => {
            track.enabled = enabled
          })
        }

        setIsMicEnabled(enabled)
      } catch (e) {
        console.error("Failed to toggle microphone:", e)
      }
    }
  }

  const toggleCamera = async () => {
    if (localParticipant) {
      try {
        const enabled = !isCameraEnabled
        await localParticipant.setCameraEnabled(enabled)

        if (context.stream instanceof MediaStream) {
          const videoTracks = context.stream.getVideoTracks()
          videoTracks.forEach((track) => {
            track.enabled = enabled
          })
        }

        setIsCameraEnabled(enabled)
      } catch (e) {
        console.error("Failed to toggle camera:", e)
      }
    }
  }

  const handleSettingsClick = () => {
    setSettingsOpen(true)
  }

  const handleSettingsClose = () => {
    setSettingsOpen(false)
  }

  const handleAudioInputChange = async (deviceId: string) => {
    if (localParticipant && room) {
      try {
        await room.switchActiveDevice("audioinput", deviceId)

        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: { exact: deviceId } },
        })

        let mediaStream: MediaStream
        if (!context.stream || !(context.stream instanceof MediaStream)) {
          mediaStream = new MediaStream()
          if (
            localParticipant.getTrackPublication(Track.Source.Camera)?.track
              ?.mediaStreamTrack
          ) {
            mediaStream.addTrack(
              localParticipant.getTrackPublication(Track.Source.Camera)?.track
                ?.mediaStreamTrack,
            )
          }
        } else {
          mediaStream = context.stream
        }

        replaceStreamTrack(
          mediaStream,
          audioStream.getAudioTracks()[0],
          "audio",
        )

        dispatch({ type: StateAction.setStream, payload: mediaStream })

        await localParticipant.setMicrophoneEnabled(true)
        dispatch({ type: StateAction.setAudioDevice, payload: deviceId })
        saveAudioInputDeviceId(deviceId)
        setIsMicEnabled(true)
      } catch (e) {
        console.error("Failed to switch audio device:", e)
      }
    }
  }

  const handleVideoInputChange = async (deviceId: string) => {
    if (localParticipant && room) {
      try {
        await room.switchActiveDevice("videoinput", deviceId)

        const videoStream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: deviceId } },
        })

        let mediaStream: MediaStream
        if (!context.stream || !(context.stream instanceof MediaStream)) {
          mediaStream = new MediaStream()
          if (
            localParticipant.getTrackPublication(Track.Source.Microphone)?.track
              ?.mediaStreamTrack
          ) {
            mediaStream.addTrack(
              localParticipant.getTrackPublication(Track.Source.Microphone)
                ?.track?.mediaStreamTrack,
            )
          }
        } else {
          mediaStream = context.stream
        }

        replaceStreamTrack(
          mediaStream,
          videoStream.getVideoTracks()[0],
          "video",
        )

        dispatch({ type: StateAction.setStream, payload: mediaStream })

        await localParticipant.setCameraEnabled(true)
        dispatch({ type: StateAction.setVideoDevice, payload: deviceId })
        saveVideoInputDeviceId(deviceId)
        setIsCameraEnabled(true)
      } catch (e) {
        console.error("Failed to switch video device:", e)
      }
    }
  }

  return (
    <>
      {showSubtitle && lastMessage && subtitlesEnabled && !isMobile && (
        <Box
          sx={{
            position: "fixed",
            bottom: "8rem",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(8px)",
            padding: "0.75rem 1.5rem",
            borderRadius: "25px",
            zIndex: 1000,
            maxWidth: "80%",
            transition: "opacity 0.3s ease-in-out",
            opacity: showSubtitle ? 1 : 0,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "white",
              textAlign: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {lastMessage}
          </Typography>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="row"
        justifyContent={"space-around"}
        alignItems={"center"}
        gap={2}
        sx={{
          position: "fixed",
          bottom: isMobile ? "5rem" : "2rem",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          backdropFilter: "blur(8px)",
          padding: "0.75rem",
          borderRadius: "50px",
          zIndex: 1000,
        }}
      >
        <Box
          {...containerProps}
          onClick={toggleCamera}
          sx={{
            ...containerProps.sx,
            backgroundColor: !isCameraEnabled
              ? "black"
              : "rgba(36, 36, 36, 0.6)",
            "&:hover": {
              backgroundColor: !isCameraEnabled
                ? "black"
                : "rgba(36, 36, 36, 0.8)",
            },
          }}
        >
          {isCameraEnabled ? (
            <VideoCamera {...iconProps} color={"white"} />
          ) : (
            <VideoCameraSlash {...iconProps} color="#DCF76D" />
          )}
        </Box>
        <Box
          {...containerProps}
          onClick={toggleMicrophone}
          sx={{
            ...containerProps.sx,
            backgroundColor: !isMicEnabled ? "black" : "rgba(36, 36, 36, 0.6)",
            "&:hover": {
              backgroundColor: !isMicEnabled
                ? "black"
                : "rgba(36, 36, 36, 0.8)",
            },
          }}
        >
          {isMicEnabled ? (
            <Microphone {...iconProps} color="white" />
          ) : (
            <MicrophoneSlash {...iconProps} color="#DCF76D" />
          )}
        </Box>
        {!isMobile && (
          <Box
            {...containerProps}
            onClick={toggleSubtitles}
            sx={{
              ...containerProps.sx,
              backgroundColor: !subtitlesEnabled
                ? "black"
                : "rgba(36, 36, 36, 0.6)",
              "&:hover": {
                backgroundColor: !subtitlesEnabled
                  ? "black"
                  : "rgba(36, 36, 36, 0.8)",
              },
            }}
          >
            {subtitlesEnabled ? (
              <Subtitles {...iconProps} color="white" />
            ) : (
              <SubtitlesSlash {...iconProps} color="#DCF76D" />
            )}
          </Box>
        )}
        {!isMobile && (
          <Box
            {...containerProps}
            onClick={toggleChat}
            sx={{
              ...containerProps.sx,
              backgroundColor: showChat ? "black" : "rgba(36, 36, 36, 0.6)",
              "&:hover": {
                backgroundColor: showChat ? "black" : "rgba(36, 36, 36, 0.8)",
              },
            }}
          >
            <ChatCircleText
              {...iconProps}
              color={showChat ? "#DCF76D" : "white"}
            />
          </Box>
        )}
        <Box {...containerProps} onClick={handleSettingsClick}>
          <Gear {...iconProps} color="white" />
        </Box>
        <Box {...containerProps} onClick={disconnect}>
          <PhoneSlash {...iconProps} color="red" />
        </Box>
      </Box>

      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={handleSettingsClose}
        audioInputs={audioInputs}
        videoInputs={videoInputs}
        selectedAudioInput={selectedAudioInput}
        selectedVideoInput={selectedVideoInput}
        handleAudioInputChange={handleAudioInputChange}
        handleVideoInputChange={handleVideoInputChange}
      />
    </>
  )
}
