import { usePersistentUserChoices } from "@livekit/components-react"
import VideocamIcon from "@mui/icons-material/Videocam"
import VideocamOffIcon from "@mui/icons-material/VideocamOff"
import React from "react"
import { useAppDispatchContext } from "../../app/Context"
import { StateAction } from "../../types/State"
import { StyledButton } from "./StyledButton"

export function VideoToggleButton({ getStyle = null }) {
  const dispatch = useAppDispatchContext()

  const { saveVideoInputEnabled, userChoices } = usePersistentUserChoices({
    preventLoad: true,
  })
  const active = userChoices?.videoEnabled || false

  const style = getStyle ? getStyle(active) : {}

  function onClick() {
    dispatch({ type: StateAction.toggleVideo })
    saveVideoInputEnabled(!active)
  }

  return (
    <StyledButton
      active={active}
      activeTip="Hide Video"
      inactiveTip="Show Video"
      onClick={onClick}
      style={style}
    >
      {active ? (
        <VideocamIcon titleAccess="Hide Video" />
      ) : (
        <VideocamOffIcon titleAccess="Show Video" />
      )}
    </StyledButton>
  )
}
