import React from "react"
import { Navigate, Params } from "react-router-dom"
import PasswordDialog from "../../components/password"
import { RoomLoader } from "../../types/Call"
import { State } from "../../types/State"
import ErrorPage from "../error-page"
import LiveRoom from "../MainScreen/LiveKitRoomScreen"
import { PostScreen } from "../PostScreen/PostScreen"
import { PreScreen } from "../PreScreen/PreScreen"

export const getRouterData = (
  state: State,
  roomLoader: ({ params }: { params: Params }) => RoomLoader,
) => {
  const commonRoutes = [
    {
      path: `rooms/:id/`,
      element: <LiveRoom />,
      loader: roomLoader,
      errorElement: <ErrorPage />,
    },
    {
      path: `rooms/:id/post`,
      element: <PostScreen />,
      loader: roomLoader,
      errorElement: <ErrorPage />,
    },
  ]

  const authRoutes = [
    {
      path: `/enter-password`,
      element: <PasswordDialog />,
      errorElement: <ErrorPage />,
    },
  ]

  const routes = [
    {
      path: "/",
      element: <PreScreen />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/:id",
      element: <PreScreen />,
      errorElement: <ErrorPage />,
    },
    ...(state.user ? commonRoutes : authRoutes),
    {
      path: "*",
      element: <Navigate to="/" />,
      errorElement: <ErrorPage />,
    },
  ]

  return routes
}
