import type {
  MessageDecoder,
  MessageEncoder,
  TrackReferenceOrPlaceholder,
  WidgetState,
} from "@livekit/components-core"
import {
  isEqualTrackRef,
  isTrackReference,
  isWeb,
  log,
} from "@livekit/components-core"
import {
  ConnectionStateToast,
  LayoutContextProvider,
  MessageFormatter,
  RoomAudioRenderer,
  useChat,
  useCreateLayoutContext,
  useDataChannel,
  useParticipants,
  usePersistentUserChoices,
  usePinnedTracks,
  useRoomContext,
  useTracks,
} from "@livekit/components-react"
import { useMediaQuery, useTheme } from "@mui/material"
import {
  LocalParticipant,
  RemoteParticipant,
  RoomEvent,
  Track,
} from "livekit-client"
import * as React from "react"
import { useNavigate } from "react-router-dom"
import {
  MobileLayout,
  WebLayout,
} from "../../components/CustomVideoConference/Layouts"
import { logAnalyticsEvent } from "../../external/analytics"
import { addMessageToCallDb } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import {
  ChatMessage,
  ChatMessageInputType,
  ChatMessageSender,
} from "../../types/Call"
import { useAppContext } from "../Context"
import "./MyVideoConference.css"

/**
 * @public
 */
export interface VideoConferenceProps
  extends React.HTMLAttributes<HTMLDivElement> {
  setParticipants: (
    participants: (RemoteParticipant | LocalParticipant)[],
  ) => void
  maxDuration: number
  onUserMessage?: () => void
  onDisconnected?: () => void
  chatMessageFormatter?: MessageFormatter
  chatMessageEncoder?: MessageEncoder
  chatMessageDecoder?: MessageDecoder
  /** @alpha */
  SettingsComponent?: React.ComponentType
}

export function MyVideoConference({
  chatMessageFormatter,
  chatMessageDecoder,
  chatMessageEncoder,
  setParticipants,
  maxDuration,
  onUserMessage,
  onDisconnected,
  SettingsComponent,
  ...props
}: VideoConferenceProps) {
  const [showChat, setShowChat] = React.useState(false)
  const [duration, setDuration] = React.useState(0)

  const context = useAppContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { userChoices } = usePersistentUserChoices({})

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (!context.call?.startedAt) return

      const startTime =
        context.call.startedAt instanceof Date
          ? context.call.startedAt
          : new Date(context.call.startedAt)

      const seconds = Math.floor(
        (new Date().getTime() - startTime.getTime()) / 1000,
      )
      setDuration(seconds)
    }, 1000)

    return () => clearInterval(timer)
  }, [context.call?.startedAt])

  const { chatMessages, send, isSending, update } = useChat()
  const participants = useParticipants()

  const room = useRoomContext()
  const navigate = useNavigate()

  React.useEffect(() => {
    setParticipants(participants)
  }, [participants])

  const lastProcessedMessage = React.useRef<number>(0)

  React.useEffect(() => {
    const req = async () => {
      if (chatMessages.length === 0) {
        return
      }
      const lastMessage = chatMessages[chatMessages.length - 1]

      if (lastMessage.timestamp === lastProcessedMessage.current) {
        return
      }
      lastProcessedMessage.current = lastMessage.timestamp

      const messageToSend: ChatMessage = {
        message: lastMessage.message,
        sentAt: new Date(lastMessage.timestamp),
        sender: lastMessage.from.isAgent
          ? ChatMessageSender.ai
          : ChatMessageSender.user,
      }

      if (messageToSend.sender === ChatMessageSender.user) {
        onUserMessage?.()
      }

      logAnalyticsEvent(
        messageToSend.sender === ChatMessageSender.ai
          ? chatMessages.length === 1
            ? AnalyticsEvents.GreetingMessage
            : AnalyticsEvents.AnswerProvided
          : AnalyticsEvents.QuestionAsked,
        {
          input_type: ChatMessageInputType.text,
        },
      )
      await addMessageToCallDb(context.call.id, messageToSend)
    }
    req()
  }, [chatMessages])

  const onMessage = (msg: any) => {
    const message = new TextDecoder("utf-8").decode(msg.payload)
    send(message)
  }

  useDataChannel("transcript", onMessage)
  const { message: latestMessage } = useDataChannel("transcript", (msg) =>
    console.log("message received", msg),
  )

  const [widgetState, setWidgetState] = React.useState<WidgetState>({
    showChat: true,
    unreadMessages: 0,
    showSettings: false,
  })
  const lastAutoFocusedScreenShareTrack =
    React.useRef<TrackReferenceOrPlaceholder | null>(null)

  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { updateOnlyOn: [RoomEvent.ActiveSpeakersChanged], onlySubscribed: false },
  )

  const widgetUpdate = (state: WidgetState) => {
    log.debug("updating widget state", state)
    setWidgetState(state)
  }

  const layoutContext = useCreateLayoutContext()

  const screenShareTracks = tracks
    .filter(isTrackReference)
    .filter((track) => track.publication.source === Track.Source.ScreenShare)

  const focusTrack = usePinnedTracks(layoutContext)?.[0]
  const carouselTracks = tracks.filter(
    (track) => !isEqualTrackRef(track, focusTrack),
  )

  React.useEffect(() => {
    if (
      screenShareTracks.some((track) => track.publication.isSubscribed) &&
      lastAutoFocusedScreenShareTrack.current === null
    ) {
      log.debug("Auto set screen share focus:", {
        newScreenShareTrack: screenShareTracks[0],
      })
      layoutContext.pin.dispatch?.({
        msg: "set_pin",
        trackReference: screenShareTracks[0],
      })
      lastAutoFocusedScreenShareTrack.current = screenShareTracks[0]
    } else if (
      lastAutoFocusedScreenShareTrack.current &&
      !screenShareTracks.some(
        (track) =>
          track.publication.trackSid ===
          lastAutoFocusedScreenShareTrack.current?.publication?.trackSid,
      )
    ) {
      log.debug("Auto clearing screen share focus.")
      layoutContext.pin.dispatch?.({ msg: "clear_pin" })
      lastAutoFocusedScreenShareTrack.current = null
    }
    if (focusTrack && !isTrackReference(focusTrack)) {
      const updatedFocusTrack = tracks.find(
        (tr) =>
          tr.participant.identity === focusTrack.participant.identity &&
          tr.source === focusTrack.source,
      )
      if (
        updatedFocusTrack !== focusTrack &&
        isTrackReference(updatedFocusTrack)
      ) {
        layoutContext.pin.dispatch?.({
          msg: "set_pin",
          trackReference: updatedFocusTrack,
        })
      }
    }
  }, [
    screenShareTracks
      .map(
        (ref) => `${ref.publication.trackSid}_${ref.publication.isSubscribed}`,
      )
      .join(),
    focusTrack?.publication?.trackSid,
  ])

  React.useEffect(() => {
    if (focusTrack == null && !isMobile) {
      const agentTrack = tracks.find(
        (track) =>
          track.source === Track.Source.Camera &&
          track.participant.isSpeaking &&
          track.participant.isAgent,
      )
      if (agentTrack) {
        layoutContext.pin.dispatch?.({
          msg: "set_pin",
          trackReference: agentTrack,
        })
      }
    }
  }, [tracks])

  // useWarnAboutMissingStyles();

  const closeChat = React.useCallback(() => {
    setShowChat(false)
  }, [])

  const openChat = React.useCallback(() => {
    setShowChat(true)
  }, [])

  const toggleChat = React.useCallback(() => {
    setShowChat((prev) => !prev)
  }, [])

  const handleDisconnect = () => {
    room.disconnect()
    onDisconnected?.()
  }

  const lastMessage = React.useMemo(() => {
    if (chatMessages.length === 0) return undefined
    return chatMessages[chatMessages.length - 1].message
  }, [chatMessages])

  const localCameraTrack = tracks.find(
    (track) =>
      track.participant.isLocal && track.source === Track.Source.Camera,
  )?.publication

  const Layout = isMobile ? MobileLayout : WebLayout
  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`
  }

  const timeRemaining = maxDuration - duration
  const showWarning = false

  return (
    <div className="lk-video-conference relative" {...props}>
      {isWeb() && (
        <LayoutContextProvider
          value={layoutContext}
          onWidgetChange={widgetUpdate}
        >
          <Layout
            tracks={tracks}
            showChat={showChat}
            audioState={userChoices.audioEnabled ?? true}
            videoState={userChoices.videoEnabled ?? true}
            localCameraTrack={localCameraTrack}
            handleDisconnect={handleDisconnect}
            toggleChat={toggleChat}
            lastMessage={lastMessage}
            duration={duration}
            maxDuration={maxDuration}
            closeChat={closeChat}
            openChat={openChat}
            chatMessages={chatMessages}
            isSending={isSending}
            send={send}
            update={update}
            SettingsComponent={SettingsComponent}
            widgetState={widgetState}
          />
        </LayoutContextProvider>
      )}
      <RoomAudioRenderer />
      <ConnectionStateToast />
    </div>
  )
}
