export enum ChatMessageSender {
  user = "user",
  ai = "ai",
}

export interface ChatMessage {
  message: string
  sentAt: Date
  sender: ChatMessageSender
  hidden?: boolean
}

export interface Call {
  id: string
  agentId?: string
  chatMessages: ChatMessage[]
  startedAt: Date
  endedAt?: Date
  rating?: number
  feedback?: string
}

export enum ChatMessageInputType {
  text = "text",
  voice = "voice",
}

export interface RoomLoader {
  matchedElement: Call
}
