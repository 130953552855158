import { keyframes } from "@emotion/react"
import {
  LiveKitRoom,
  usePersistentUserChoices,
} from "@livekit/components-react"
import "@livekit/components-styles"
import { styled } from "@mui/material/styles"
import { VideoPresets } from "livekit-client"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { logAnalyticsEvent } from "../../external/analytics"
import { endCall } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import {
  deleteLivekitRoom,
  getLivekitToken,
  isDev,
  isLocalDev,
  isStaging,
} from "../../utils/general"
import { useAppContext } from "../Context"
import { MyVideoConference } from "./MyVideoConference"

const IDLE_TIMEOUT = 5 * 60
const MAX_CALL_DURATION = 15 * 60 // 15 minutes for testing

const serverUrl = isLocalDev
  ? "ws://localhost:7880"
  : isDev
    ? "wss://dev-38rwe5wn.livekit.cloud"
    : isStaging
      ? "wss://staging-56walglr.livekit.cloud"
      : "wss://prod-w0h88kyi.livekit.cloud"

const loadingDots = keyframes`
  0%, 20% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60%, 100% {
    content: "...";
  }
`

const LoadingText = styled("div")`
  &::after {
    content: ".";
    animation: ${loadingDots} 1.5s infinite;
  }
`

export default function LiveRoom() {
  const [token, setToken] = useState("")
  const [isConnected, setIsConnected] = useState(false)
  const [participants, setParticipants] = useState([])
  const [maxCallDuration, setMaxCallDuration] = useState(MAX_CALL_DURATION)
  const [lastMessageTime, setLastMessageTime] = useState(Date.now())
  const context = useAppContext()
  const navigate = useNavigate()
  const { id } = useParams()

  const { userChoices } = usePersistentUserChoices({})

  useEffect(() => {
    const req = async () => {
      const tokenFromBe = await getLivekitToken(id)
      setToken(tokenFromBe)
    }
    req()

    const idleCheck = setInterval(() => {
      const idle = Date.now() - lastMessageTime
      if (idle >= IDLE_TIMEOUT * 1000) {
        logAnalyticsEvent(AnalyticsEvents.IdleTimeout)
        onDisconnected?.()
      }
    }, 1000)

    return () => {
      clearInterval(idleCheck)
    }
  }, [lastMessageTime, navigate])

  useEffect(() => {
    let callTimeout: NodeJS.Timeout | null = null

    if (maxCallDuration > 0) {
      callTimeout = setTimeout(() => {
        onDisconnected()
      }, maxCallDuration * 1000)
    }

    return () => {
      if (callTimeout) {
        clearTimeout(callTimeout)
      }
    }
  }, [maxCallDuration])

  const onDisconnected = async () => {
    const finalDuration = Math.floor(
      (new Date().getTime() - context.call?.startedAt?.getTime()) / 1000,
    )

    logAnalyticsEvent(AnalyticsEvents.CallEnded, {
      duration: finalDuration,
    })

    await endCall(id)

    if (participants.every((p) => p.isLocal || p.isAgent)) {
      await deleteLivekitRoom(id)
    }
    navigate("/rooms/" + id + "/post")
  }

  const roomOptions = {
    publishDefaults: {
      simulcast: true,
      videoSimulcastLayers: [VideoPresets.h540, VideoPresets.h360],
    },
    audioCaptureDefaults: {
      deviceId: userChoices.audioDeviceId,
    },
    videoCaptureDefaults: {
      deviceId: userChoices.videoDeviceId,
    },
  }

  const handleUserMessage = React.useCallback(() => {
    setLastMessageTime(Date.now())
  }, [])

  const handleConnected = React.useCallback(() => {
    setIsConnected(true)
  }, [])

  const handleDisconnected = React.useCallback(() => {
    setIsConnected(false)
  }, [])

  return (
    <LiveKitRoom
      token={token}
      serverUrl={serverUrl}
      audio={{ deviceId: userChoices.audioDeviceId }}
      data-lk-theme="default"
      style={{ height: "100dvh" }}
      onDisconnected={onDisconnected}
      onConnected={handleConnected}
      options={roomOptions}
    >
      {!isConnected && (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.2rem",
          }}
        >
          <span>Connecting to room</span>
          <LoadingText />
        </div>
      )}
      {isConnected && (
        <MyVideoConference
          setParticipants={setParticipants}
          maxDuration={maxCallDuration}
          onUserMessage={handleUserMessage}
          onDisconnected={onDisconnected}
        />
      )}
    </LiveKitRoom>
  )
}
