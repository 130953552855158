export enum Method {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum ReturnTypes {
  json = "json",
  blob = "blob",
}

export enum Endpoints {
  offer = "offer",
  video = "video",
  getToken = "getToken",
  deleteRoom = "deleteRoom",
  agent = "agent",
}

export enum SecondPathParams {
  usage = "usage",
}
