export function replaceStreamTrack(
  stream: MediaStream,
  track: MediaStreamTrack,
  kind: "audio" | "video",
) {
  const oldTrack = stream.getTracks().find((track) => track.kind === kind)
  if (oldTrack) {
    stream.removeTrack(oldTrack)
    oldTrack.stop()
  }
  stream.addTrack(track)
}
