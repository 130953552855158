import { Box } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppDispatchContext } from "../app/Context"
import { StateAction } from "../types/State"

export default function PasswordDialog() {
  const dispatch = useAppDispatchContext()
  const navigate = useNavigate()
  const { state } = useLocation()

  const [open, setOpen] = useState(false)
  const [password, setPassword] = useState("")

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    dispatch({
      type: StateAction.setUserEnteredPassword,
      payload: password,
    })
    handleClose()
    navigate(`/`, { state })
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
      }}
    >
      <Button variant="outlined" onClick={handleClickOpen}>
        Unlock Beyond Presence
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleClose()
          },
        }}
      >
        <DialogTitle>Unlock Beyond Presence</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the password you have received from the team.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="password"
            label="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
