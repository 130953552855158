import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  IconButton,
  Link,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import * as Sentry from "@sentry/react"
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import GoogleImage from "../../../public/web_light_sq_SI@4x.png"
import { MainButton } from "../../components/mainButton"
import { TitleTextButton } from "../../components/titleTextButton"
import { logAnalyticsEvent } from "../../external/analytics"
import { addCallDb } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import { StateAction } from "../../types/State"
import { getDefaultAgentId } from "../../utils/general"
import { useAppContext, useAppDispatchContext } from "../Context"

export function UserInput() {
  const context = useAppContext()
  const dispatch = useAppDispatchContext()
  const stream = context.stream
  const user = context.user
  const provider = new GoogleAuthProvider()
  const auth = getAuth()
  const { id } = useParams()
  const [errorMessage, setErrorMessage] = useState("")

  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  function isEmailValid(email: string) {
    return /^\S+@\S+\.\S+$/.test(email) || email === ""
  }

  function isFormValid() {
    return stream !== null && user != null
  }

  const signInWithGoogle = async () => {
    setErrorMessage("")
    return signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential.accessToken
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        setErrorMessage(errorCode + " " + errorMessage)
        Sentry.captureException(error)
      })
  }

  const joinMeeting = async () => {
    const agentId = id == null || id === "" ? getDefaultAgentId : id
    // console.log("agentId", agentId)
    const conversationId = await addCallDb(context.user, agentId)
    // console.log("conversationId", conversationId)

    dispatch({
      type: StateAction.setCall,
      payload: {
        ...context.call,
        id: conversationId,
        agentId: agentId,
        chatMessages: [],
        startedAt: new Date(),
      },
    })

    context.stream.getTracks().forEach((track) => {
      track.stop()
    })

    logAnalyticsEvent(AnalyticsEvents.MeetingJoined)
    navigate(`/rooms/${conversationId}`)
  }

  const joinButton = (
    <MainButton
      onClick={joinMeeting}
      disabled={!isFormValid()}
      text="Join Meeting"
    />
  )

  const signInButton = (
    <IconButton
      style={{
        backgroundColor: "transparent",
        padding: 0,
        display: "inline-block",
      }}
      onClick={signInWithGoogle}
    >
      <img
        src={GoogleImage}
        alt="Google Sign in"
        style={{ width: "183px", height: "40px" }}
      />
    </IconButton>
  )

  const tosLink = (
    <Link sx={{ textDecoration: "none" }} href="#">
      Terms of Service
    </Link>
  )
  const privacyLink = (
    <Link sx={{ textDecoration: "none" }} href="#">
      Privacy Policy
    </Link>
  )
  const joinText = (
    <>
      By clicking on "join meeting" you agree to our {tosLink} and {privacyLink}
      .
    </>
  )

  return (
    <Box sx={{ width: isMobile ? "80vw" : "20vw" }}>
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setErrorMessage("")}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {user == null ? (
        <TitleTextButton
          title="Welcome 👋"
          text={<>Please sign in to join the meeting</>}
          button={signInButton}
        />
      ) : (
        <>
          <TitleTextButton
            title={!isMobile ? "Ready to join?" : ""}
            text={joinText}
            button={joinButton}
          />
        </>
      )}
    </Box>
  )
}
