import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import { formatTime } from "../../utils/func"

export interface CustomCallTimerProps {
  duration: number
  maxDuration: number
  chatEnabled: boolean
}

export default function CustomCallTimer({
  duration,
  maxDuration,
  chatEnabled,
}: CustomCallTimerProps) {
  const timeRemaining = Math.max(0, maxDuration - duration)
  const formattedTimeRemaining = formatTime(timeRemaining)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const rightPosition = React.useMemo(() => {
    if (!isMobile && chatEnabled) {
      return `calc(27% + 1rem)`
    }
    return "1rem"
  }, [chatEnabled, isMobile])

  return (
    <Box
      padding={"6px 12px"}
      position="fixed"
      top={"1rem"}
      right={rightPosition}
      color="white"
      sx={{ backgroundColor: "#59514B", backdropFilter: "blur(3px)" }}
      borderRadius={"50px"}
    >
      <Typography color={"white"} variant="body2">
        Remaining time: {formattedTimeRemaining}
      </Typography>
    </Box>
  )
}
