import { UsageDetails } from "../types/UsageDetails"

export const getAvailableCredits = (usageDetails: UsageDetails): number => {
  const usedCredits = usageDetails.current_usage / 1000
  const totalCredits = usageDetails.usage_limit / 1000
  const remainingCredits = totalCredits - usedCredits
  return remainingCredits
}

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`
}
