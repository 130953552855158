import { usePersistentUserChoices } from "@livekit/components-react"
import { Microphone, MicrophoneSlash } from "@phosphor-icons/react"
import React from "react"
import { useAppDispatchContext } from "../../app/Context"
import { StateAction } from "../../types/State"
import { StyledButton } from "./StyledButton"

export function MicToggleButton({ getStyle = null }) {
  const dispatch = useAppDispatchContext()

  const { saveAudioInputEnabled, userChoices } = usePersistentUserChoices({
    preventLoad: true,
  })
  const active = userChoices?.audioEnabled || false

  const style = getStyle ? getStyle(active) : {}

  function onClick() {
    dispatch({ type: StateAction.toggleAudio })
    saveAudioInputEnabled(!active)
  }

  return (
    <StyledButton
      active={active}
      activeTip="Mute Audio"
      inactiveTip="Unmute Audio"
      onClick={onClick}
      style={style}
    >
      {active ? <Microphone /> : <MicrophoneSlash />}
    </StyledButton>
  )
}
