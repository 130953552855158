import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles"
import CssBaseline from "@mui/material/CssBaseline"
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
} from "@mui/material/styles"
import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import mixpanel from "mixpanel-browser"
import React, { useCallback, useEffect, useState } from "react"
import { createBrowserRouter, Params, RouterProvider } from "react-router-dom"
import { Loader } from "../components/loader"
import { RoomLoader } from "../types/Call"
import { StateAction } from "../types/State"
import { isDev, isLocalDev, isStaging } from "../utils/general"
import { appReducer, initialState } from "./AppContext"
import { AppContext, AppDispatchContext } from "./Context"
import { usePersistedReducer } from "./persistedReducer"
import { getRouterData } from "./routes/router"

if (!isLocalDev) {
  mixpanel.init(
    isDev
      ? "cd6d221954f1477b41cac2c257910ab7"
      : isStaging
        ? "aed2ff230af1d514b983de7680ee30b0"
        : "06c39334f3ed58bee551506171e0ef81",
    {
      debug: false,
      track_pageview: true,
      persistence: "localStorage",
    },
  )
}

const firebaseConfig = isDev
  ? {
      apiKey: "AIzaSyC788lZ2gkp8HjPNDhpWNBHsLCVUGaOksY",
      authDomain: "bey-dev.firebaseapp.com",
      projectId: "bey-dev",
      storageBucket: "bey-dev.appspot.com",
      messagingSenderId: "347029147333",
      appId: "1:347029147333:web:8c9c85b285179c0be4eaac",
      measurementId: "G-NBGKVM0BES",
    }
  : isStaging
    ? {
        apiKey: "AIzaSyC9TAzKVcWIruehblFd-4gI7MpPyFM2I7Q",
        authDomain: "bey-staging.firebaseapp.com",
        projectId: "bey-staging",
        storageBucket: "bey-staging.firebasestorage.app",
        messagingSenderId: "418945392850",
        appId: "1:418945392850:web:5e7c4d06e9ffaf5bbb8d0b",
        measurementId: "G-2CPNB48FMS",
      }
    : {
        apiKey: "AIzaSyCx2GDcIjQQ9nTtIbHHf6wGwvnp_2V7jX4",
        authDomain: "bey-prod.firebaseapp.com",
        projectId: "bey-prod",
        storageBucket: "bey-prod.appspot.com",
        messagingSenderId: "352012720018",
        appId: "1:352012720018:web:27a46e753a2e56983f534b",
        measurementId: "G-BN8E3VD31V",
      }

const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const db = getFirestore(app)
auth.useDeviceLanguage()

const theme = materialExtendTheme({
  typography: {
    fontFamily: [
      "figtree",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
})

function App() {
  const { state, dispatch } = usePersistedReducer(
    appReducer,
    initialState,
    "state",
  )
  const [loading, setLoading] = useState(true)

  const roomLoader = useCallback(
    ({ params }: { params: Params }): RoomLoader => ({
      matchedElement: state.call,
    }),
    [state.call],
  )

  const router = createBrowserRouter(getRouterData(state, roomLoader))

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch({
          type: StateAction.setUser,
          payload: { uid: user.uid, name: user.displayName, email: user.email },
        })
        if (!isLocalDev) {
          mixpanel.identify(user.uid)
          mixpanel.people.set({ $name: user.displayName, $email: user.email })
        }
      } else {
        dispatch({ type: StateAction.resetState })
        if (!isLocalDev) {
          mixpanel.reset()
        }
      }
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: theme }}>
          <JoyCssVarsProvider>
            <CssBaseline enableColorScheme />
            <RouterProvider router={router} />
          </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  )
}

export default App
