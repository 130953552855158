import { Box } from "@mui/material"
import React from "react"
import LogoSvg from "../../assets/LogoW.svg"

export const AppLogo: React.FC = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "20px",
        left: "20px",
        width: "240px",
        height: "auto",
        zIndex: 1,
      }}
    >
      <img
        src={LogoSvg}
        alt="Logo"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
    </Box>
  )
}
