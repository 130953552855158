import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "../Context"

const verySecretPassword = "ynVdx6Cz6Y"

export function useAppLock(id: string) {
  const navigate = useNavigate()
  const { userEnteredPassword } = useAppContext()

  useEffect(() => {
    if (userEnteredPassword !== verySecretPassword) {
      navigate(`/enter-password`, { state: id })
    }
  }, [userEnteredPassword])
}
