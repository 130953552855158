import React, { useEffect, useState } from "react"

import MicIcon from "@mui/icons-material/Mic"
import VideocamIcon from "@mui/icons-material/Videocam"

import { usePersistentUserChoices } from "@livekit/components-react"
import { Stack, useMediaQuery, useTheme } from "@mui/material"
import { StateAction } from "../../types/State"
import { replaceStreamTrack } from "../../utils/media"
import { useAppContext, useAppDispatchContext } from "../Context"
import { MediaSelectForm } from "./MediaSelectForm"

export function MediaSelect() {
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([])
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([])

  const context = useAppContext()
  const stream = context.stream
  const dispatch = useAppDispatchContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { saveAudioInputDeviceId, saveVideoInputDeviceId, userChoices } =
    usePersistentUserChoices({
      preventLoad: true,
    })

  const microphone = userChoices?.audioDeviceId || ""
  const camera = userChoices?.videoDeviceId || ""

  useEffect(() => {
    const requestMedia = async () => {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const foundMicrophones: MediaDeviceInfo[] = []
        const foundCameras: MediaDeviceInfo[] = []
        devices.forEach((device) => {
          if (device.kind === "audioinput") {
            foundMicrophones.push(device)
          } else if (device.kind === "videoinput") {
            foundCameras.push(device)
          }
        })
        setMicrophones(foundMicrophones)
        setCameras(foundCameras)
        if (foundMicrophones.length > 0 && !microphone) {
          saveAudioInputDeviceId(foundMicrophones[0].deviceId)
          dispatch({
            type: StateAction.setAudioDevice,
            payload: foundMicrophones[0].deviceId,
          })
        }
        if (foundCameras.length > 0 && !camera) {
          saveVideoInputDeviceId(foundCameras[0].deviceId)
          dispatch({
            type: StateAction.setVideoDevice,
            payload: foundCameras[0].deviceId,
          })
        }
      })
    }
    requestMedia()
  }, [stream])

  async function onMicrophoneChange(e) {
    const deviceId = e.target.value
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: { exact: deviceId } },
    })
    replaceStreamTrack(stream, audioStream.getAudioTracks()[0], "audio")
    dispatch({ type: StateAction.setAudioDevice, payload: deviceId })
    console.log("saving audio device id", deviceId)
    saveAudioInputDeviceId(deviceId)
  }

  async function onCameraChange(e) {
    const deviceId = e.target.value
    const videoStream = await navigator.mediaDevices.getUserMedia({
      video: { deviceId: { exact: deviceId } },
    })
    replaceStreamTrack(stream, videoStream.getVideoTracks()[0], "video")
    dispatch({ type: StateAction.setVideoDevice, payload: deviceId })
    console.log("saving video device id", deviceId)
    saveVideoInputDeviceId(deviceId)
  }

  return (
    <>
      {stream != null && stream.getTracks != null ? (
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{ alignItems: "center" }}
        >
          {microphones.length > 0 && (
            <MediaSelectForm
              name="Microphone"
              icon={<MicIcon />}
              options={microphones}
              currentOption={microphone}
              onChange={onMicrophoneChange}
            ></MediaSelectForm>
          )}
          {cameras.length > 0 && (
            <MediaSelectForm
              name="Camera"
              icon={<VideocamIcon />}
              options={cameras}
              currentOption={camera}
              onChange={onCameraChange}
            ></MediaSelectForm>
          )}
        </Stack>
      ) : null}
    </>
  )
}
